<template>
  <div>
    <div class="header">
      <div class="user-avatar">
        <van-image
            width="2.0rem"
            height="2.0rem"
            fit="cover"
            :src="require('@/assets/head-portrait.png')"
        />
      </div>
    </div>
    <div>
      <van-cell :title="$t('history')" icon="underway-o" is-link :border="false" to="history"/>
      <van-cell :title="$t('contact')" icon="service-o" is-link :url="toTelegram"/>
    </div>
    <layout></layout>
  </div>
</template>

<script>
import layout from '@/layout/index'
import {getAppDefaultLanguage} from "@/utils/init";

export default {
  name: "index",
  components: {
    layout,
  },
  data() {
    return {
      telegram: {
        'hi': 'https://t.me/+F0XOxDz-W-k3MTk1',
        'pt': 'https://t.me/+_lI99YVzgbRjZDdl',
        'es': 'https://t.me/+_NyxI2S1A0k5ZmE1',
        'en': 'https://t.me/+WB_hdo3a0pY2NGRl',
      },
      toTelegram: ''
    }
  },
  mounted() {
    let appLanguage = getAppDefaultLanguage()
    this.toTelegram = this.telegram[appLanguage] ? this.telegram[appLanguage] : ''
    console.log(this.toTelegram)
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 4.58rem;
  background: linear-gradient(to bottom, var(--minor-color), var(--main-color));
  display: flex;
  justify-content: center;
  align-items: center;
}

.van-cell {
  background-color: var(--main-color);
  color: var(--title-color)
}

.van-cell__right-icon {
  color: var(--title-color)
}

</style>
